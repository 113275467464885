html {
	font-size: 100px;
	font-size: 5.208334vw;
	overflow-x: hidden;
	// &.overflowy
 }	// 	overflow-y: hidden

body {
	* {
		font-size: 1em;
		font-family: 'Roboto', sans-serif;
		line-height: 1.4em; } }

@each $line, $size, $b-color in (main-form, .18em, $pink), (middle-form, .18em, $grey), (footer-form, .18em, $grey), (header-modal, .18em, $grey) {
	.#{$line}__input {
		display: flex;
		width: 2.5em;
		height: 0.4em;
		input {
			line-height: 1;
			width: 100%;
			font-size: $size;
			border-radius: 1.1em;
			border: .055em solid $black;
			padding: 0 0 0 1.12em;
			outline: none;
			&:focus, &:active {
				box-shadow: 0 0 0 0.11em $b-color; }
			&.invalid_phone {
				border: .01em solid red; } } } }

button {
	display: flex;
	width: 2em;
	height: .4em;
	padding: 0;
	border: none;
	outline: none;
	background-color: $blue;
	border-radius: .2em;
	align-items: center;
	justify-content: center;
	box-shadow: 0em .03em .03em rgba(0, 0, 0, .25);
	transition: all .3s cubic-bezier(0.63, 0.45, 0.15, 0.95);
	cursor: pointer;
	p {
		color: $white;
		font-size: .18em;
		line-height: 1; }
	&:active {
		box-shadow: none;
		transform: translateY(.01em); }
	&:hover, &:focus {
		background-color: #49BFFF; } }

.cpoint {
	cursor: pointer;
	&:hover {
		p {
			color: $pink; } } }
.policy {
	p {
		font-size: .14em;
		a {
			transition: all .3s cubic-bezier(0.79, 0.35, 0.24, 0.73);
			&:hover, &:active {
				color: $pink; } } } }

.pink {
	color: $pink; }

picture {
	display: flex;
	img {
		pointer-events: none;
		user-select: none; } }

.header-section {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	display: flex;
	justify-content: center;
	background-color: $white;
	box-shadow: 0em .04em .1em .03em rgba(0, 0, 0, .25);
	header {
		width: 13.5em;
		height: 1em;
		display: flex;
		background-color: $white;
		align-items: center;
		justify-content: center;
		.logo {
			// margin: .17em 0 .18em .1em
			img {
				width: 2.32em;
				height: .65em; } }
		.navigator {
			display: flex;
			align-self: center;
			margin-left: auto;
			ul {
				display: flex;
				list-style: none;
				li {
					a {
						display: flex;
						text-decoration: none;
						font-size: .18em;
						color: $black;
						height: 2em;
						align-items: center; }
					&:not(:last-child) {
						margin-right: .35em; } } } } }
	.header-btn {
		margin-left: auto;
		align-self: center; }
	.social {
		&__inst {
			position: relative;
			display: flex;
			width: .3em;
			height: .3em;
			margin-left: .3em;
			a {
				width: 100%;
				height: 100%;
				p {
					width: .3em;
					height: .3em;
					background: url(/img/icons/inst.png) no-repeat;
					background-position: 0 0;
					background-size: contain;
					transition: all .3s cubic-bezier(0.79, 0.35, 0.24, 0.73);
					&:hover {
						background: url(/img/icons/inst-colored.png) no-repeat;
						background-position: 0 0;
						background-size: contain; } } } } } }

.main {
	position: relative;
	background-color: #050506;
	min-height: 8em;
	overflow: hidden;
	margin-top: 1em;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	&-offer {
		position: absolute;
		color: $white;
		z-index: 1;
		display: flex;
		flex-flow: row;
		width: 13.5em;
		&__pic {
			margin-top: .42em;
			img {
				position: relative;
				width: 3em;
				height: 1.69em;
				z-index: 1; } } }
	&-text {
		margin-left: .4em;
		text-transform: uppercase;
		text-shadow: 0 0.04em 0.01em rgba(0,0,0,.7);
		&__h1 {
			h1 {
				font-weight: bold;
				font-size: .48em;
				span {
					&:first-child {
						display: block;
						font-weight: 400;
						font-size: .75em; } } } } //36px
		&__h2 {
			h2 {
				font-size: .36em;
				font-weight: 400;
				span {
					font-weight: 500;
					font-size: 1.33em; } } } }
	&-right-pic {
		position: absolute;
		right: 0;
		z-index: 0;
		img {
			width: 19.2em;
			height: 8em; } }
	&-form {
		background-color: $black;
		width: 3.3em;
		height: 4em;
		border: .02em solid $white;
		border-radius: .3em;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 1;
		margin-top: -.72em;
		margin-left: auto;
		.forma {
			width: 2.5em;
			height: 3.2em; }
		&__h3 {
			text-align: center;
			h3 {
				color: $white;
				font-size: .18em; } }
		&__input {
			display: flex;
			width: 100%;
			height: 0.4em;
			input {
				line-height: 1;
				width: 100%;
				font-size: .18em;
				border-radius: 1.1em;
				border: none;
				outline: none;
				padding-left: 1.12em;
				&:focus, &:active {
					box-shadow: 0 0 0 0.11em $pink; } }
			&:nth-child(2) {
				margin-top: .35em;
				margin-bottom: .3em; } }

		.btn {
			margin: .35em auto .2em;
			p {
				color: $white;
				font-size: .18em; }
			&:active {
				transform: translateY(.01em); } }
		.policy {
			color: $white;
			text-align: center;
			a {
				color: $white;
				&:hover, &:active {
					color: $pink; } } } } }

.numbers {
	// height: 3.8em
	position: relative;
	padding: .4em 0 1em;
	width: 13.5em;
	display: flex;
	margin: 0 auto;
	flex-flow: column;
	&__h2 {
		// padding-top: .4em
		text-align: center;
		h2 {
			font-size: .36em;
			font-weight: 400; } }
	img {
		position: absolute;
		right: 0;
		bottom: 0;
		width: 3.4em;
		height: 3.07em; }
	&-row {
		display: flex;
		justify-content: center;
		padding-top: .5em;
		&__column {
			align-items: center;
			text-align: center;
			display: flex;
			flex-flow: column;
			width: 2.25em;
			img {
				position: relative;
				width: auto;
				height: .9em;
				margin-bottom: .3em; }
			p {
				font-size: .16em;
 }				// margin-bottom: auto
			&:not(:last-child) {
				margin-right: auto; } } } }

.steps {
	background-color: $black;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	.container {
		padding: .5em 0;
		color: $white;
		display: flex;
		flex-flow: column;
		align-items: center;
		width: 13.5em; }
	&__h2 {
		text-align: center;
		h2 {
			font-weight: 400;
			font-size: .36em; } }
	&__p {
		margin-top: .25em;
		a {
			display: flex;
			color: $white;
			font-size: .36em;
			text-decoration: none;
			transition: all .3s cubic-bezier(0.79, 0.35, 0.24, 0.73);
			&:hover, &:active {
				color: $pink; } }
		p {
			font-size: .24em; } }
	&__line {
		width: 10em;
		height: .02em;
		margin-top: .15em;
		background-color: #fff; }
	.arrow {
		a {
			display: flex;
			transition: all .3s cubic-bezier(0.79, 0.35, 0.24, 0.73);
			&:hover, &:active {
				transform: translateY(.1em);
				.img {
					background: url(/img/icons/pink-arrow.svg) no-repeat;
					background-position: 0 0;
					background-size: contain; } }
			.img {
				background: url(/img/icons/arrow.svg) no-repeat;
				background-position: 0 0;
				background-size: contain;
				margin-top: .25em;
				width: .56em;
				height: .34em;
				transition: all .3s cubic-bezier(0.79, 0.35, 0.24, 0.73);
				&:hover, &:active {
					background: url(/img/icons/pink-arrow.svg) no-repeat;
					background-size: contain;
					background-position: 0 0; } } } } }
.parallax {
	background: url(/img/bridge.jpg) no-repeat;
	background-attachment: fixed;
	height: 3.5em;
	background-size: cover; }

.middle {
	display: flex;
	background-color: #EBEBEB;
	// img
	// 	position: absolute
	// 	top: 16.63em
	// 	width: 4.64em
	// 	height: 2.96em
	// 	z-index: 1
	.middle-form {
		margin: .5em auto;
		.forma {
			display: flex;
			flex-flow: column; }
		&__row {
			display: flex;
			margin-top: 0.5em; }
		&__input {
			&:nth-child(1) {
				margin-right: .5em; }
			&:nth-child(2) {
				margin-right: .72em; } }
		&__h3 {
			h3 {
				font-size: .24em; } }
		.policy {
			margin-top: .3em;
			a {
				color: $black;
				transition: all .3s cubic-bezier(0.79, 0.35, 0.24, 0.73);
				&:hover, &:active {
					color: $pink; } } } } }

.table-section {
	padding: .5em 0 1em;
	position: relative;
	overflow: hidden;
	&__h2 {
		h2 {
			text-align: center;
			color: $white;
			text-shadow: 0em .055em 0em $black;
			font-size: .36em; } }
	img {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		object-fit: cover; }
	.table {
		margin-top: .35em;
		table {
			color: $white;
			background-color: rgba(0,0,0, .7);
			border-collapse: collapse;
			margin: 0 auto;
			border: .03em solid $white;
			caption {
				font-size: .18em;
				text-align: right;
				margin-bottom: 0.5em;
				color: $grey; }
			span {
					color: white; }
			tr {
				td {
					text-align: center;
					border: .01em solid $white; } }
			thead {
				.table__column {
					background-color: #0069A3; } } }
		&__column {
			width: 1.85em;
			height: .65em;
			&:first-child {
				width: 2.3em; }
			.grub {
				display: none; }
			.order {
				display: block;
				justify-content: center;
				font-size: .14em;
				color: #d5d5d5; } }
		&__p {
			font-size: .24em;
			transition: all .3s cubic-bezier(0.79, 0.35, 0.24, 0.73); } } }

.contacts-section {
	display: flex;
	width: 13.5em;
	margin: 0 auto;
	.contacts {
		display: flex;
		flex-flow: column;
		align-items: center;
		margin-right: .30em;
		justify-content: center;
		&__h2 {
			// margin-top: .7em
			margin-bottom: .25em;
			text-align: center;
			h2 {
				font-weight: 400;
				font-size: .36em; } }
		&__address {
			margin: .15em 0; }
		#map-pointer {
			width: .32em;
			height: .43em; }
		#phone-pointer {
			width: .20em;
			height: .34em; }
		#mail-pointer {
			width: .26em;
			height: .20em; }
		a {
			display: flex;
			font-size: .18em;
			text-decoration: none;
			color: $black;
			transition: all .3s cubic-bezier(0.79, 0.35, 0.24, 0.73);
			&:hover, &:focus {
				color: $pink; } } }
	#map {
		margin: .5em 0;
		width: 8em;
		height: 3.7em;
		margin-left: auto;
		overflow: hidden;
		filter: drop-shadow(0px 6px 9px rgba(0, 0, 0, 0.25)); } }

.footer-feedback {
	padding: .5em 0 .65em 0;
	background-color: $black;
	display: flex;
	position: relative;
	overflow: hidden;
	.container {
		width: 13.5em;
		margin: 0 auto; }
	.footer-form {
		position: relative;
		z-index: 1;
		width: 5.45em;
		&__h3 {
			text-align: center;
			h3 {
				line-height: 1;
				color: $white;
				font-size: .36em;
				font-weight: 400; } }
		&__textarea {
			display: flex;
			textarea {
				line-height: 1;
				width: 99.5%;
				font-size: .18em;
				border-radius: 1.1em;
				border: .055em solid $black;
				padding: .5em 1.12em 0;
				outline: none;
				resize: none;
				&:focus, &:active {
					box-shadow: 0 0 0 0.11em $grey; } } }
		&__input, &__textarea {
			width: 99.5%;
			margin-top: .25em; }
		.btn {
			margin: .2em auto .25em; }
		.policy {
			color: $grey;
			text-align: center;
			a {
				color: $grey;
				&:hover, &:active {
					color: $pink; } } } }
	img {
		width: 19.2em;
		height: 5.4em;
		position: absolute;
		top: 0;
		right: 0; } }

//Modal part
.mask {
	visibility: hidden;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0,0,0, .85);
	opacity: 0; }

.header-modal {
	opacity: 0;
	visibility: hidden;
	position: fixed;
	background-color: #EDEDED;
	display: flex;
	align-content: center;
	justify-content: center;
	border-radius: .2em;
	padding: .54em .58em;
	width: max-content;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	&__input, button {
		margin: 0 auto; }
	&__input {
		&:nth-child(4) {
			margin-top: .3em;
			margin-bottom: .3em; } }
	.close {
		position: absolute;
		background-color: $white;
		top: -.24em;
		right: -.35em;
		width: .22em;
		height: .22em;
		box-shadow: none;
		transform: none;
		z-index: 2;
		&:focus, &:hover {
			box-shadow: 0 0 0 0.01em $pink; } }
	&__h3 {
		text-align: center;
		h3 {
			font-weight: 400;
			font-size: .24em; } }
	.btn {
		margin-top: .3em;
		margin-bottom: .2em;
		margin-left: auto;
		// img
		// 	width: .11em
 }		// 	height: .11em
	.policy {
		text-align: center;
		a {
			color: $black;
			transition: .1s cubic-bezier(0.79, 0.35, 0.24, 0.73);
			&:hover, &:active {
				color: $pink; } } }
	#choice {
		text-align: center;
		margin-top: .2em;
		#choice__p {
			text-decoration: underline;
			font-size: .18em; }
		#choice__order {
			margin-top: 1.2em;
			font-size: .2em; } } }
.show-modal {
	opacity: 1;
	z-index: 2;
	visibility: visible;
	transition: .4s cubic-bezier(0.79, 0.35, 0.24, 0.73);
	+ .header-modal {
		opacity: 1;
		z-index: 2;
		visibility: visible;
		transition: .8s cubic-bezier(0.79, 0.35, 0.24, 0.73); } }
//End of Modal part

//Thanks Section
.thanks {
	transition: .5s cubic-bezier(0.79, 0.35, 0.24, 0.73);
	opacity: 0;
	visibility: hidden;
	top: 0;
	left: 0;
	position: fixed;
	background-color: $black;
	width: 100vw;
	height: 100vh;
	z-index: 3;
	display: flex;
	flex-flow: column;
	justify-content: center;
	&__h3 {
		text-align: center;
		z-index: 1;
		display: flex;
		margin-left: 2.6em;
		h3 {
			color: $white;
			line-height: 1;
			font-size: .36em;
			font-weight: 400; } }
	.btn {
		width: 2.32em;
		margin-left: 4.62em;
		margin-top: .5em; }
	&__picture {
		position: fixed;
		top: 0;
		right: 0;
		width: 50%;
		height: 100%;
		background: url(/img/machine.png) no-repeat;
		background-position: 0 0;
		background-size: cover; } }
.visible {
	opacity: 1;
	visibility: visible; }
//End of Thanks Section

#scroller {
	position: fixed;
	z-index: 2;
	bottom: .8em;
	right: 1em;
	background: url(/img/icons/scroll-to-top.svg) no-repeat;
	background-size: contain;
	background-position: 0 0;
	width: .58em;
	height: .58em;
	cursor: pointer;
	display: none; }

.ymaps-2-1-77-copyrights-pane {
	display: none; }

// <section class="steps" id="howwework">
// 	<div class="steps__h2">
// 		<h2>Как мы работаем</h2>
// 	</div>
// 	<div class="steps-row">
// 		<div class="steps-row__column">
// 			<div class="steps-row__picture">
// 				<img src="/img/step1.png" alt="Шаг 1">
// 			</div>
// 			<p>Позвоните нам</br>или оставьте заявку</p>
// 		</div>
// 		<div class="steps-row__column">
// 			<div class="steps-row__picture">
// 				<img src="/img/step2.png" alt="Шаг 2">
// 			</div>
// 			<p>Мы обработаем</br>Вашу заявку</p>
// 		</div>
// 		<div class="steps-row__column">
// 			<div class="steps-row__picture">
// 				<img src="/img/step3.png" alt="Шаг 3">
// 			</div>
// 			<p>Вы получите</br>подтверждение</p>
// 		</div>
// 		<div class="steps-row__column">
// 			<div class="steps-row__picture">
// 				<img src="/img/step4.png" alt="Шаг 4">
// 			</div>
// 			<p>Мы отправляем</br>Ваш заказ</p>
// 		</div>
// 		<div class="steps-row__column">
// 			<div class="steps-row__picture" id="bg-none">
// 				<img src="/img/step5.png" alt="Шаг 5">
// 			</div>
// 			<p>Вы получаете</br>товар</p>
// 		</div>
// 	</div>
// 	</div>
// 	<!-- <picture>
// 		<source srcset="img/tape-for-numbers.webp" type="image/webp">
// 		<img src="img/tape-for-numbers.png" alt='Шлифовальная лента 3M Cubitron II 984F в свернутом виде' draggable="false" loading="lazy">
// 	</picture> -->
// </section>
