@media screen and ( max-width: 1024px ) {
    html {
        font-size: calc(5.208334vw + 180%); }
    .policy p {
        font-size: .18em; }
    .header-section {
        header {
            width: 95%; } }
    .main-offer {
        width: 95%;
        margin-top: .9em;
        img {
            position: absolute;
            top: -1.85em;
            left: 1em; } }
    .main-form {
        .forma {
            height: 3.6em; } }
    .numbers {
        width: 95%; }
    .parallax {
        background-position: 52% 0; }
    .contacts-section {
        width: 95%; }
    .footer-feedback {
        padding: 0;
        .footer-form {
            padding: .5em .15em .65em;
            background-color: rgba(0,0,0, .85); } } }

@media screen and ( max-width: 1440px ) {
    html {
        font-size: calc(5.208334vw + 100%); } }
